import { zodResolver } from "@hookform/resolvers/zod";
import {
  KikProspectSignUpFields as ProspectSignUpFields,
  kikProspectSignUpFieldsSchema as prospectSignUpFieldsSchema,
  KikUserZodSchemas as ZodSchemas,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import classNames from "classnames";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Typewriter, { TypewriterClass } from "typewriter-effect";

import Checkbox from "~/components/form/checkbox";
import Errors from "~/components/form/errors";
import InputField from "~/components/form/input-field";
import { useAuthContext } from "~/contexts/auth";
import { useBreakpoint } from "~/contexts/breakpoint";
import Logger from "~/services/logger/logger";
import Editorials from "~/types/editorials";
import appRoutes from "~/utils/app-routes";
import Constants from "~/utils/constants";
import contentfulUtils from "~/utils/contentful-utils";
import crossCountryUtils from "~/utils/crossCountry-utils";

import EditorialLink from "../editorials/editorial-link/editorial-link";
import Button from "./button";
import EscapeNewLine from "./escape-new-line";
import HighlightedText from "./highlighted-text";
import styles from "./newsletter-box.module.scss";

type Props = {
  entry: Entry<Editorials.Footer>;
};

export default function NewsletterBox({ entry }: Props) {
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const t = useTranslations();
  const formRef = useRef<HTMLFormElement>(null);

  const { signUpProspect, isLoading, authError } = useAuthContext();
  const [submitted, setSubmitted] = useState<boolean>(!1);
  const currentCountry = crossCountryUtils.getCurrentCountryCode(router);
  const currentLanguage = crossCountryUtils.getCurrentLanguageCode(router);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<ProspectSignUpFields>({
    ...Constants.USE_FORM_VALIDATION_DEFAULTS,
    resolver: zodResolver(prospectSignUpFieldsSchema),
  });

  const onSubmit = (data: ProspectSignUpFields) => {
    setSubmitted(!0);
    signUpProspect(data)
      .then(() => {
        Logger.instance.debug("SIGNUP NEWSLETTER OK");
        router.push(`/${appRoutes.SIGN_UP_AWAIT}`);
      })
      .catch(() => {
        Logger.instance.debug("SIGNUP NEWSLETTER KO");
      });
  };

  // move focus to the form in case of server error
  useEffect(() => {
    if (authError) {
      formRef.current?.focus();
    }
  }, [authError]);

  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  const { title, titleAnimated, subtitle, highlightedText, linkPrivacyPolicy } = entry.fields;
  const [currentTitleAnimated, setCurrentTitleAnimated] = useState(titleAnimated?.[0] ?? "");
  const [isPaused, setIsPaused] = useState(false);

  type TypewriterClassExtended = TypewriterClass & { go?: boolean };
  const [instanceTypewriter, setInstanceTypewriter] = useState<TypewriterClassExtended>();

  return (
    <div className={styles.titleAndNewsletterContainer}>
      <h2 className={styles.wrapperTitle}>
        <p className={styles.visuallyHidden}>{`${title} ${currentTitleAnimated}`}</p>
        <p aria-hidden>
          <span
            className={contentfulUtils.isHighlightText(title) ? styles.titleHighlighted : styles.title}
            {...inspectorMode?.getProps("title")}
          >
            <HighlightedText text={title} />
          </span>
          {titleAnimated ? (
            <button
              aria-label={isPaused ? t("generic.startAnimation") : t("generic.stopAnimation")}
              className={classNames(styles.title, isPaused ? styles.start : styles.stop)}
              onClick={() => {
                if (isPaused) {
                  instanceTypewriter?.start();
                  setIsPaused(false);
                } else {
                  instanceTypewriter?.stop();
                  setIsPaused(true);
                }
              }}
              {...inspectorMode?.getProps("titleAnimated")}
            >
              {/* react-typed: https://www.npmjs.com/package/typewriter-effect */}
              {titleAnimated.length > 1 ? (
                <Typewriter
                  onInit={(typewriter: TypewriterClassExtended) => {
                    titleAnimated.forEach((title) => {
                      typewriter
                        .typeString(title)
                        .callFunction(() => {
                          if (instanceTypewriter?.go) {
                            setCurrentTitleAnimated(title);
                          }
                        })
                        .pauseFor(4000)
                        .deleteAll();
                    });

                    typewriter.start();
                    typewriter.go = true;
                    setInstanceTypewriter(typewriter);
                  }}
                  component={"span"}
                  options={{
                    loop: true,
                    cursor: "",
                    skipAddStyles: true,
                    delay: 80,
                    deleteSpeed: 50,
                  }}
                />
              ) : (
                titleAnimated[0]
              )}
            </button>
          ) : null}
        </p>

        <p className={styles.subtitle} {...inspectorMode?.getProps("subtitle")}>
          <EscapeNewLine text={subtitle} />
        </p>
        {highlightedText ? (
          <p className={styles.highlightedText} {...inspectorMode?.getProps("highlightedText")}>
            <EscapeNewLine text={highlightedText} />
          </p>
        ) : null}
      </h2>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} tabIndex={-1} noValidate>
        <input {...register("CountryID")} type="hidden" value={currentCountry} />
        <input {...register("LanguageID")} type="hidden" value={currentLanguage} />
        <InputField
          type="email"
          label={t("generic.form.insert_email_label")}
          isOptional
          error={
            errors.EmailAddress?.message
              ? t(errors.EmailAddress?.message, {
                  fieldName: t("generic.email"),
                  maxLength: ZodSchemas.emailMaxlength,
                })
              : undefined
          }
          {...register("EmailAddress")}
          isSubmitting={isLoading}
          onClearErrors={() => clearErrors("EmailAddress")}
          className={styles.newsletterInput}
        />
        <Errors
          error={submitted ? authError : null}
          style={{ gridColumn: breakpoint === "desktop" ? "2 span" : "1" }}
        />
        <Checkbox
          label={t("generic.footerPrivacyDisclaimer")}
          error={
            errors.MarketingOptIn?.message ? t("error.fieldRequired", { fieldName: t("generic.thisField") }) : undefined
          }
          {...register("MarketingOptIn")}
          className={styles.newsletterCheckbox}
        />
        <EditorialLink entry={linkPrivacyPolicy} className={styles.link} />
        <Button
          disabled={isLoading}
          loading={isLoading}
          className={styles.submitField}
          variant="secondary"
          type="submit"
          value="submit"
        >
          {t("generic.newsletterSignUp")}
        </Button>
      </form>
    </div>
  );
}
