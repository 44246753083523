import { Entry } from "contentful";
import { MutableRefObject } from "react";

import Editorials from "~/types/editorials";

/**
 * Utility functions for handling accordions.
 *
 * @namespace
 */
const accordionUtils = {
  /**
   * Check all accordions except the selected one. If an open accordion is found, a click is forced on it.
   *
   * @param {HTMLButtonElement} disclosureRef - The selected accordion.
   * @param {MutableRefObject<HTMLButtonElement[]>} refAccordions - The array of accordions.
   *
   * @returns {void}
   */
  handleClosingDisclosures(
    disclosureRef: HTMLButtonElement,
    refAccordions: MutableRefObject<HTMLButtonElement[]>
  ): void {
    const otherRefsWithoutSelected = refAccordions.current?.filter((ref) => {
      return ref !== disclosureRef;
    });

    otherRefsWithoutSelected.forEach((ref) => {
      if (ref?.getAttribute("data-headlessui-state") === "open") {
        ref?.click();
      }
    });
  },

  /**
   * Generates a slug for the editorial accordion based on the provided title.
   *
   * @param {string | undefined} editorialAccordionTitle - The title of the editorial accordion.
   * @returns {string} The generated slug.
   */
  getEditorialAccordionSlug(editorialAccordionTitle?: string): string {
    return `${editorialAccordionTitle?.toLowerCase().replace(/\s/g, "-")}` ?? "";
  },

  /**
   * Generates a slug for the accordion based on the provided data.
   *
   * @param {Entry<Editorials.Accordion>} accordion - The accordion entry.
   * @param {string | undefined} editorialAccordionTitle - The title of the editorial accordion.
   * @returns {string} The generated slug.
   */
  getAccordionSlug(accordion: Entry<Editorials.Accordion>, editorialAccordionTitle?: string): string {
    return (
      accordion.fields?.slug ??
      `${editorialAccordionTitle?.toLowerCase().replace(/\s/g, "-")}-${accordion.fields.title
        ?.toLowerCase()
        .replace(/\s/g, "-")}` ??
      ""
    );
  },
};

export default accordionUtils;
